/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 18px;

$baseLineHeight: 30px;

$h1Size: 45px;

$h2Size: 40px;

$h3Size: 35px;

$h4Size: 30px;

$h5Size: 20px;

$h6Size: 17px;

$mainFont: Open Sans, sans-serif;

$displayFont: Open Sans, sans-serif;

$iconFont: icomoon;

// Project colors

$primary: #6bc6db;
$secondary: #272f3f;
$light: #fff;
$medium: #F2F2F0;
$dark: #262D3B;
$border: #bbb;
$alert: #D85D00;

$baseGap: 18px;

$rowMaxWidth: 1620px;

$columnCount: 12;

$baseBorder: 1px solid $border;

$outline: 1px solid $primary;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	times: "×", 
	minus: "-", 
	angle-right: "❯", 
	plus: "+", 
	angle-up: "↑", 
	exclamation: "!", 
	video-camera: "\f03d", 
	chevron-right: "\f054", 
	info-circle: "\f05a", 
	phone: "\f095", 
	caret-down: "\f0d7", 
	envelope: "\f0e0", 
	file-text: "\f15c", 
	user-circle: "\f2bd", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
	),
	tiny: (
		width: em(480px, $bpContext), 
		cols: (6),
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (3),
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (3, 5, 6, 7, 8),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (2, 3, 4, 5, 6, 7, 8, 9, 10),
		prefix: (1, 2, 3, 4, 5, 6),
		sufix: (1, 2, 3, 4, 5, 6),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (2, 3, 8, 9, 10),
		prefix: (1),
	),
	huge: (
		width: em(1364px, $bpContext), 
		cols: (2, 3, 7, 10),
	),
	full: (
		width: em(1520px, $bpContext), 
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (auto),
	),
);

