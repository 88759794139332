* {
  margin: 0;
  padding: 0;

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in _config.scss
html {
  background: $secondary;
  box-sizing: border-box;
  color: $light;
  font-family: $mainFont;
  font-size: 100% * ($baseFontSize / 16px);
  hyphens: manual;
  line-height: rem($baseLineHeight);

  /* disable text resize in landscape. e.g. on iphone */
  text-size-adjust: none;
}

body {
  line-height: rem($baseLineHeight);
  opacity: 1 !important;
}

iframe,
[data-iframe] {
  border: none;
  width: 100%;
}

address {
  font-style:normal;
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @extend .marginBottom;
  display: block;
  font-family: $displayFont;
  text-rendering: optimizeLegibility;
  hyphens: auto;
  span {
    font-weight: 400;
    color: $primary;
    display: block;
  }

  /* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/  
}

h1,
.h1 {
  font-size: 175%;
  line-height: 1.2em;
  span {
    font-size: $h5Size;
    line-height: 1.5em;
  }  
  @include breakpoint(large) {
    font-size: rem($h1Size);
    line-height: 1em;
    span {
      font-size: $h4Size;
    }    
  }
}

h2,
.h2 {
  font-size: 155%;  
  line-height: 1.2em;
  @include breakpoint(large) {
    font-size: rem($h2Size);
  }
}

h3,
.h3 {
  font-size: 135%;
  line-height: 1.4em;
  span {
    font-size: rem(18px);
  }
  @include breakpoint(large) {
    font-size: rem($h3Size);
    span {
      font-size: rem(22px);
    }    
  }
}

h4,
.h4 {
  font-size: 115%;
  line-height: 1.3em;
  span {
    font-size: rem(20px);
  }
  @include breakpoint(large) {
    font-size: rem($h4Size);
    line-height: 1.3em;
    span {
      font-size: rem(24px);
    }    
  }
}

h5,
.h5 {
  font-size: rem($h5Size);
}

h6,
.h6 {
  font-size: rem($h6Size);
}

/**
 * Links
 */
a {
  color: $light;
  word-wrap: break-word;
  text-decoration: underline;
  transition: .3s ease-in-out; 

  &:hover, &:focus, &:active{
    text-decoration: none;
  }

  img {
    border: none;
  }

  &[href^="tel"] {
    color: inherit;
    text-decoration: underline;
  }
}

a:not(.btn):focus,
[tabindex]:focus {
  outline: $outline;
  outline-offset: rem(5px);
}

hr,
.hr {
  display: flex;
  background: $medium;
  opacity: .3;
  border: none;
  clear: both;
  height: rem(1px);
  margin: 3rem 0;
  flex-basis: 100%;
}

ul,
ol {
  list-style: none;

  &.styledList {
    li { 
      padding: rem(15px) rem(25px);
      margin-bottom: rem(7px);
      &:not(:last-child) {
        border-bottom: 1px solid $primary;
      }
      a { 
        &.active, &:hover {
          text-decoration: none;
        }
      }
      &:before {
        font-family: $iconFont;
        content: map-get($iconMap, chevron-right);
        display: inline-block;
        text-indent: rem(-20px);
        font-size: rem(15px);
      }
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
}

dl {
  &.styledList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      border: 1px solid $light;
      padding: rem(15px);
    }

    dt {
      padding-bottom: 0;
      font-weight: bold;
      border-bottom: none;
    }

    dd {
      padding-top: 0;
      border-top: none;

      + dt {
        margin-top: rem(10px);
      }

      + dd {
        margin-top: rem(-15px);
      }
    }
  }

  &.floatList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      vertical-align: top;
      float: left;
    }

    dt {
      padding-right: 0.5rem;
      width: 40%;
      clear: left;
    }

    dd {
      width: 60%;
    }
  }
}

ol {
  &.styledList
  {
    counter-reset: item;
  
    li {
      counter-increment: item;
  
      &:before {
        content: counters(item, ".") ". ";
      }
    }
  
    ol {
      margin-bottom: 0;
  
      li {
        &:before {
          content: counters(item, ".") " ";
          margin-right: rem(5px);
        }
      }
    }
  }
}

/*
 * responsive images
 */

img {
  vertical-align: bottom;
  float: none;
  height: auto;
  max-width: 100%;
  width: 100%;

  &[src^='http://cms.'], &[src^='https://cms.'] {
    max-width: none;
    width: auto;
  }

  [data-rel] &,
  .lightbox-image & {
    margin-bottom: 0;
  }

  &[data-src] {
    @extend %lazyloading;
  }
}

/**
 * Tables
 */
main {
  table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-size: rem($baseFontSize);

    @include breakpoint(small, max) {
      overflow-x: auto;
      display: block;
    }

    caption {
      text-align: left;
      padding: rem($baseGap) rem($baseGap) rem(25px);
      display: table-caption;
      font-weight: bold;
      font-size: 1.2rem;
    }

    thead {
      border-collapse: collapse;
      display: table-header-group;
    }

    tbody {
      width: 100%;
      overflow-x: scroll;
      display: table-row-group;

      tr {
        padding: rem($baseGap) 0;

        &:not(:last-child) {
          border-bottom: rem(1px) solid $medium;
        }
      }
    }

    tr {
      display: table-row;
    }

    th,
    td {
      padding: rem($baseGap);
      display: table-cell;
      vertical-align: top;
    }

    @include breakpoint(small, max) {
      td {
        min-width: 50vw;
      }
    }
  }
}
