// HEADER
//////////////////////////////
#header {
	position: relative;
	padding: rem(80px) rem($baseGap) rem(50px) rem($baseGap);
	width: 100%;
	margin-bottom: 4rem;
	text-align: center;
	@include responsiveImage('layout/bgHeader.jpg', (tiny, medium), true);

		body.privatIndex &, body.privatAktionen &, body.privatServiceInfo &, body.privatProdukte &, body.privatProdukteGree &, body.privatProdukteDaikin &, body.privatProdukteLg & {
			@include responsiveImage('/images/layout/bgHeader-privat.jpg', (tiny, medium), true);
			background-size: cover;
			background-position: center;
		}
		body.gewerbeIndustrie & {
			@include responsiveImage('/images/layout/bgHeader-gewerbe.jpg', (tiny, medium), true);
			background-size: cover;
			background-position: center;
		}
	.branding {
		margin: auto;
		display: block;
		max-width: 11rem;
		height: 100px;
		background: url(/images/layout/branding.svg) no-repeat center/contain;
	}
	@include breakpoint(large) {
		margin-bottom: 8rem;
		.branding {
			display: none;
		}
	}


	&.lazyLoaded {
		background-size:cover
	}

	@include breakpoint(large) {
		padding: 16rem 0 8rem;
	}

	.claim {
		font-size: $h4Size;
		line-height: 1.2em;
		color: $primary;

		span {
			display: block;
			font-size: rem(20px);
			font-weight: bold;
			color: $light;
		}
		@include breakpoint(medium) {
			font-size: $h3Size;
			span {
				font-size: rem(30px);
			}
		}
	}

	.teaserContainer {
		background-color: $secondary;
		border-radius: 10px;
		font-size: rem(15px);
		padding: 3rem 0;
		.content {
			margin: 0 auto;
			text-align: center;
			img {
				display: block;
				width: 35%;
				margin: 0 auto 2rem auto;
			}
			a { 
				font-weight: bold;
				text-decoration: none;
				font-size: rem(15px);
				&.active, &:hover {
				 		text-decoration: underline;
				}
			}
			&:not(:last-child){
				border-right: 1px solid white;
			}
			@include breakpoint(medium) {
				font-size: $baseFontSize;
			}
			@include breakpoint(large) {
				&:not(:last-child){
					padding-bottom: 0;
				}	
			}		
		}
		@include breakpoint(small) {
			padding: 3rem 0;
		}
		@include breakpoint(large) {
			padding: 4rem 0;
			flex-wrap: nowrap;
		}

		.iconSwiper {
			.swiper-pagination {
				position: relative;
				bottom: 0;
			}
		}
	}
	body.cmsBackend & {
		display: none;
	}
	.btnContainer {
		display: flex;
		flex-direction: column;
		padding-top: 1rem;
		@include breakpoint(medium) {
			flex-direction: row;
			justify-content: center;
			padding-top: 2.5rem;
		}
		a.btn {
			@include breakpoint(medium) {
				width: 300px;

				&:nth-child(1) {
					margin-right: 5%;
				}
				&:nth-child(2) {
					margin-left: 5%;
				}
			}
		}
	}
}
// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	p, ol, ul, dl, table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);

		&.max-image {
			max-width: 200px;
		}
	}


	.whiteBanner, .whiteBox {
		background-color: $light;
		color: $secondary;
		padding: 4rem 0;
		margin: 4rem 0;
		@include breakpoint(large) {	
			margin: 8rem 0;
		}
		a {
			color: $secondary;
		}
		img {
			margin-bottom: 0;
			&.max-image {
				max-width: 250px;
				@include breakpoint(medium) {
				max-width: 300px;
				}
				&.lazyLoaded {
					
					background: none;
					}
			}
		}
		hr, .hr{
			background-color: $secondary;
		}
	}
	.bottomBanner {
		margin-bottom: -4rem;
		@include breakpoint(large) {
			margin-bottom: -8rem;
		}
	}
	.CMSBanner {
		margin-bottom: 0;
		.modalGallery {
			text-align: left;
			img {
				margin-bottom: 1.66667rem;
			}		
		}
	}
	.whiteBox {
		padding: 3rem;
		margin: 0;
		.modalGallery {
			.responsive {
				a {
					overflow: hidden;
					width: auto;
			    	height: rem(190px);
			    	display: flex; 
				    justify-content: center; 
				    align-items: center;  
				    margin-bottom: 2rem;
				      	
				    img {
				    	height: 100%;
				    	width: auto;
				    	margin-bottom: 0;
				    }
				}
			}
		}
	}

	.zertifikat {
		p {
			margin-top: 2rem;
    		text-align: center;
    		font-weight: 600;
    		@include breakpoint(medium) {
    			max-width: 80%;
    		}
    		@include breakpoint(large) {
    			max-width: 50%;
    		}
		}
	}

	.flex-center {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		@include breakpoint(large) {
			flex-direction: row;
			
		}
		.btn {
			margin-top: 2rem;
			@include breakpoint(medium) {
				margin-top: 0;
			}
		}
	}
	.view-list {
		background-color: $secondary;
		color: $light;
		padding: 2rem;
		&:not(:last-child) {
			margin-bottom: 2rem;
		}
		.huge-list {
			@include breakpoint(huge) {
				li {
					float: left;
					width: 48%;
					margin-right: 1rem;
				}
			}
		}
	}

	.teaserSwiper {
		margin: 4rem 0 -4rem 0;
		@include breakpoint(large) {
			margin: 8rem 0 -8rem 0;
		}
		.swiper-wrapper {
			.swiper-slide {
					div {
					position: relative;
					display: flex;
					flex-direction: column;
					align-content: center;
					align-items: center;
					justify-content: center;
					height: rem(400px);
					padding: 0 2rem;
					text-align: center;
					@include breakpoint(medium) {
						height: rem(500px);
					}
					@include breakpoint(large) {
						padding: 0;
					}
					@include breakpoint(huge) {
						height: rem(600px);
					}
					span {
						color: #0bcd48;
					}
				}
				.btn {
					a {
						color: $secondary;
				    }
				}
			}
			.swiper-pagination {
				position: absolute;
				bottom: 1rem;
				left: 50%;
				transform: translatex(-50%);
				.swiper-pagination-bullet {
					width: 15px;
	    			height: 15px;
	    			border: 1px solid white;
					background: none;
					opacity: 1;
					margin: 0 .2rem;
				}
				.swiper-pagination-bullet-active {
					background: $light;
				}
			}
		}
	}

	.schleife {
		&:not(:last-child) {			
			padding-bottom: 3rem;
			border-bottom: 1px solid $secondary;
			margin-bottom: 3rem;
		}
	}

	.contactBanner {
		@include responsiveImage('layout/bgFooter.jpg', (tiny, medium), false);
		background-position: center;
		background-size: cover;
		padding: 8rem 0;
		margin-top: 4rem;
		@include breakpoint(large) {
			margin-top: 8rem;
		}

		&.lazyLoaded {
			background-size:cover
		}
		.content {
			display: flex;
			flex-direction: column;
			align-content: center;
			align-items: center;		
			text-align: center;	
			color: $light;
		}
	}
	.partnerContainer {
		text-align: center;
		margin-bottom: -4rem;
		@include breakpoint(large) {
			margin-bottom: -8rem;
		}
		h3 {
			margin-bottom: 3rem;
		}
		.partnerSwiper {
			.swiper-slide {
	    		text-align: center;
				img {
					max-height: 3.5rem;
					width: auto;
				}
			}
		}	
	}

	.branding {
		background-color: $light;
		img {
			display: block;
			margin: 0 auto;
			max-width: rem(600px);
		}
	}

	.halfUL {
		@include breakpoint(large) {
			li {			
				width: 48%;
				float: left;
				margin-right: 1rem;
			}
		}
	}
}




// CMS
.cmsBackend {
	.partnerContainer {
		.swiper-wrapper {
			display: block;
			box-sizing: border-box;		
		}
	}
	.cmsBackendBox {
		.hinweis {
			color: red;
			font-weight: 800;
		}
	}
	.cmsFrontendBox {
		display: none;
	}
}

body:not(.cmsBackend) {
	.cmsBackendBox { 
		display: none;
	}
	.cmsFrontendBox {
		display: inline-table;
		.CMSbtn {
			a {
				 @extend %animatedTransform;
				 background: $light;
				 border: none;
				 border-radius: 5px;
				 color: $secondary;
				 cursor: pointer;
				 display: inline-block;
				 font-family: $mainFont;
				 font-size: rem($baseFontSize);
				 line-height: rem($baseLineHeight);
				 padding: 1rem 2rem;
				 text-align: center;
				 text-decoration: none;
				 transition: 300ms linear;
				 box-shadow: -2px -2px 0px rgba(255,255,255,0),
				             -2px -2px 12px rgba(255,255,255,0),
				             inset 2px 2px 4px rgba(255,255,255,0.1),
				             3px 3px 12px rgba(0,0,0,0.3);
				  	&:after {
				       font-family: $iconFont;
				       content: map-get($iconMap, file-text); 
				       font-size: rem(15px);
				       margin-left: .5rem;  
				     }
				    
					&:hover, &:active, &:focus {
					   box-shadow: inset -2px -2px 8px #fff, 
					               inset -2px -2px 12px rgba(255,255,255,0.5), 
					               inset 2px 2px 4px rgba(255, 255, 255, 0.19), 
					               inset 3px 3px 12px rgba(0, 0, 0, 0.69);
						}
			}
		}
		.videoContainer {
			margin-top: 2rem;
		}
	}
}



#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

.privatIndexBanner {
		@include responsiveImage('layout/bgPrivatIndexBanner.jpg', (tiny, medium), false);
		background-position: center;
		background-size: cover;
		padding: 8rem 0;
		margin: 4rem 0;

		&.lazyLoaded {
			background-size:cover
		}
		.content {
			display: flex;
			flex-direction: column;
			align-content: center;
			align-items: center;		
			text-align: center;	
			color: $light;
			@include breakpoint(medium) {
				flex-direction: row;
				justify-content: space-evenly;
			}
		}
		a.btn {
			min-width: 250px;
		}
}
.gewerbeBanner {
		@include responsiveImage('layout/bgGewerbeBanner.jpg', (tiny, medium), false);
		background-position: center;
		background-size: cover;
		padding: 8rem 0;
		margin: 4rem 0;
		@include breakpoint(medium) {
			font-size: 1.2rem;
		}
		@include breakpoint(large) {
			font-size: 1.5rem;
		}

		&.lazyLoaded {
			background-size:cover
		}
		.content {
			color: $light;
			ul {
				@include breakpoint(medium) {
					column-count: 2;
				}
				li {
					break-inside: avoid-column;
				}
			}
		}
		a.btn {
			min-width: 250px;
		}
}

.downloadBtns {
	margin-bottom: 1.5rem;
}

body:not(.cmsBackend) {
	.cmsBackendBox { 
		display: none;
	}
	div.newsRow {
		margin-bottom: 4rem;
		@include breakpoint(large) {
			margin-bottom: 8rem;
		}
		.cmsFrontendBox {
			display: inline-table;
			background: rgba(255,255,255, 0.9);
			color: $dark;
			padding-top: 4rem;
			padding-bottom: 4rem;
			@include breakpoint(medium) {
				padding: 4rem;
			}
			.CMSbtn {
					a {
						 @extend %animatedTransform;
						 background: $dark;
						 border: none;
						 border-radius: 5px;
						 color: $light;
						 cursor: pointer;
						 display: inline-block;
						 font-family: $mainFont;
						 font-size: rem($baseFontSize);
						 line-height: rem($baseLineHeight);
						 padding: 1rem 2rem;
						 text-align: center;
						 text-decoration: none;
						 transition: 300ms linear;
						 box-shadow: -2px -2px 0px rgba(255,255,255,0),
						             -2px -2px 12px rgba(255,255,255,0),
						             inset 2px 2px 4px rgba(255,255,255,0.1),
						             3px 3px 12px rgba(0,0,0,0.3);
						  	&:after {
						       font-family: $iconFont;
						       content: map-get($iconMap, file-text); 
						       font-size: rem(15px);
						       margin-left: .5rem;  
						     }
						    
							&:hover, &:active, &:focus {
							   box-shadow: inset -2px -2px 8px $dark, 
							               inset -2px -2px 12px rgba(0,0,0,0.5), 
							               inset 2px 2px 4px rgba(0, 0, 0, 0.19), 
							               inset 3px 3px 12px rgba(255, 255, 255, 0.69);
								}
					}
				}
				.videoContainer {
					margin-top: 2rem;
				}
				img {
					margin-bottom: $baseGap;
				}
			}

	}
}
.footerOH{
	margin-bottom: 130px;
	@include breakpoint (medium) {
		margin-bottom: 0px;
	}
}
.warppi{
	flex-wrap: wrap;
	flex-direction: column !important;
}