// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $secondary; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;
}
%buttonHover, %buttonActive {
	// Link Hover
	color: $primary;
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, caret-down);
	transform:rotate(-90deg);

	@include breakpoint($break) {
		transform:rotate(0deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;
	.brand {
		a {
			background: url(/images/layout/branding.svg) no-repeat center/contain;	
			display: block;
			height: 100%;
		}
	}
	.naviTop {
		display: flex;
		flex-direction: column;
		margin-bottom: 2rem;
		.content {
			display: none;
		}
		.navi {
			li {
				a, span {
					font-weight: 400;
					text-transform: capitalize;
				}
			}
		}
	}
	.contactBar {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-end;
		@include breakpoint(cut) {
			flex-direction: row;
		}
		a, span {			
			text-decoration: none;
			@include breakpoint(large) {
				padding-left: 0.4rem;
			}
			@include breakpoint(giant) {
				padding-left: 1rem;
			}
			@include breakpoint(full) {
				padding-left: 2rem;
			}
			&.email, &.phone, &.fb {
		      &:before {
		        font-family: $iconFont;
		        // content: map-get($iconMap, envelope);
		        font-size: rem(19px);
		        margin-right: 1rem;  
		      }
		    }
			&.email {
		      &:before {
		        content: map-get($iconMap, envelope); 
		      }
		    }			
			&.phone {
		      &:before {
		        content: map-get($iconMap, phone); 
		      }
		    }			
		   &.active, &:hover {
				color: $primary;
			} 			
		}
		a {
			&.fb {
		      img {
		      	width: rem(19px);
		      	margin-right: 1rem; 
		      	margin-bottom: .45rem;
		      }
		      &:after {
		      	content: "Facebook"
		      }
		      @include breakpoint($break) {
		      	img {
		      		margin-right: 0;
		      	}
		      	&:after {
		      		display: none;
		      	}
		      }
		    }			
		}
	}
	nav {
		@include ul {
			&.navi {
				li {
					padding: .8rem 0;
					&:not(:last-child) {
						border-bottom: 1px solid $light;
						@include breakpoint(large) {
							border: none;
						}
					}
					a, span {
						font-weight: bold;
						text-decoration: none;
						text-transform: uppercase;
						cursor: pointer;
						&.active, &:hover {
							@extend %buttonActive;
						}
					}
					@include hasSub {
						@include sub {
							background: $navBg;
							padding: 1rem;
							li {
								padding: 1rem;
								&:not(:last-child) {
									border-bottom: 1px solid $light;
								}
							}
						&:before {
							padding: 1em rem($baseGap);
							background-color: darken($navBg, 6%);
						}
					}
				}
				@include navBack {
				}
			}
		}
	}
	}	
	body.cmsBackend & {
		display: none;
	}
}

#navAdd {
	@extend nav;
	@include ul {
		&.navi {
			padding-top: 1rem;
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		padding: 2rem 0;
		.brand {
			margin: .6rem 0;
		}
		.naviTop {
    		margin-bottom: 1rem;
    		@include breakpoint(cut) {
    			flex-direction: row-reverse;
    		}
    		.content {
    			display: block;
    			width: 100%;
				@include breakpoint(cut) {
					border-left: 1px solid $light;
					margin-left: 1rem;	
				}
    		}
		}
		nav {
			ul {
				&.navi {
					li {
						padding: 0;
						&.hasSub {
							.sub {
							}
						}
						&.navBack {
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	.info {
		font-size: rem(15px);
	}

	@include breakpoint($break) {
		display: none;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
#footer {	
	margin: 3rem 0;
	padding: 1rem 0;
	.border {
		@include breakpoint(huge) {			
			border-left: 1px solid $light;
			height: 100%;
			padding-left: 3rem;
		}
	}
	.naviAdd {
		display: flex;
		flex-direction: column;

		li {
			padding: .3rem 0;
			a { text-decoration: none;
				&.active, &:hover {
			 		text-decoration: underline;
			 	}
			 }
			ul { padding-left: 2rem;
				li {
					font-size: $h6Size;
					padding: .25rem 0;
					&:before {
						font-family: $iconFont;
						content: map-get($iconMap, chevron-right);	
						font-size: rem(13px);
						margin-right: .5rem;					
					}
				}
			}
			.facebook{
				&:before {
					content: url(/images/layout/fb.svg);
					display: inline-block;
					width: rem(14px);
					margin-right: .5rem;
				}
			}
		}
	}	
	.contact {
		margin-top: 3rem;
		@include breakpoint(huge) {
			margin-top: 0;
		}
		p, dt, dd {
			padding: .3rem 0;
			a { text-decoration: none;
				&.active, &:hover {
					text-decoration: underline;
				}
			}
		}
		p:nth-child(3), dd:nth-child(2) {
			padding-bottom: 1.3rem;
		}

		img {
			max-width: 5rem;
			margin-top: 2rem;
		}
	}
}

