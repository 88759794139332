.marginBottom {
  margin-bottom: rem($baseLineHeight);
}

.highlight {
  color: $secondary;
}

.btn {
  @extend %animatedTransform;
  margin-top: 1rem;
  background: $light;
  border: none;
  border-radius: 5px;
  color: $secondary;
  cursor: pointer;
  display: inline-block;
  font-family: $mainFont;
  font-size: rem($baseFontSize);
  line-height: rem($baseLineHeight);
  padding: 1rem 2rem;
  text-align: center;
  text-decoration: none;
  transition: .3s ease-in-out; 
  box-shadow: -2px -2px 0px rgba(255,255,255,0),
              -2px -2px 12px rgba(255,255,255,0),
              inset 2px 2px 4px rgba(255,255,255,0.1),
              3px 3px 12px rgba(0,0,0,0.3);
    &.pdf, &.info, &.video, &.contact, &.certificate {
      &:after {
        font-family: $iconFont;
        font-size: rem(15px);
        margin-left: .5rem;  
      }
    }
    &.pdf {
      &:after {
        content: map-get($iconMap, file-text); 
      }
    }
    &.info {
      &:after {
        content: map-get($iconMap, info-circle); 
      }
    }
    &.video {
      &:after {
        content: map-get($iconMap, video-camera); 
      }
    }
    &.contact {
      &:after {
        content: map-get($iconMap, user-circle); 
      }
    }
  

  &:hover, &:active, &:focus {
    box-shadow: inset -2px -2px 8px #fff, 
                inset -2px -2px 12px rgba(255,255,255,0.5), 
                inset 2px 2px 4px rgba(255, 255, 255, 0.19), 
                inset 3px 3px 12px rgba(0, 0, 0, 0.69);
  }



  *[class^="icon"] {
    margin-left: 0.5rem;
    vertical-align: middle;
  }
}

.clear {
  clear: both;
  float: none;
}

.clearfix {
  @include pie-clearfix;
}

.hidden {
  display: none;
}

.hideText {
  @include hideText();
  display: inline-block;
  overflow: hidden;
  width: 0;
}

.floatLeft {
  float: left;
}

img.floatLeft {
  margin-right: 1rem;
}

.floatRight {
  float: right;
}

img.floatRight {
  margin-left: 1rem;
}

.fluid {
  height: auto;
  width: 100%;
}

.nonFluid {
  width: auto !important;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.wide {
  width: 100%;
  display: block;
}

/* 16:9 video resolutions */
.videoContainer2 {
  &:not(.videoTag) {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 75.25%;
    position: relative;

    iframe {
      border: none;
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  video {
    width: 100%;
    height: auto;
  }
}

.videoContainer {
  &:not(.videoTag) {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;

    iframe {
      border: none;
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  video {
    width: 100%;
    height: auto;
  }
}
.googleMaps {
  @extend .videoContainer;
  margin-bottom: 2rem;

  @include breakpoint(medium) {
    height: rem(400px);
    margin-bottom: 0;
  }
}

body {
  &:before {
    $content: "default:" + $bpContext;
    width: str_length($content) * 6pt + $baseGap * 2;

    @each $point, $width in $gridMap {
      $content: $content + "...." + $point + ":" + nth(map-get(map-get($gridMap, $point), width), 1);

      @include breakpoint($point) {
        width: str_length($content) * 6pt + $baseGap * 2;
      }
    }

    content: "#{$content}";
    display: none !important; /* Prevent from displaying. */
  }

  &.devmode:before,
  &.debug:before {
    background: $alert;
    border-color: $alert;
    border-style: solid;
    border-width: 0 rem($baseGap);
    color: $light;
    display: block !important;
    font-family: Courier;
    font-size: 10pt;
    left: 0;
    line-height: 2.5;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    white-space: nowrap;
    z-index: 99999;
  }
  &:hover:before {
    opacity: 0.5;
    pointer-events: none;
  }
}

// Animations
%animatedTransform {
  transition: 0.3s;
}

.notificationArea {
  position: fixed;
  bottom: rem(40px);
  left: rem(40px);
  z-index: 99999;
  visibility: hidden;
  
  @include breakpoint(large) {
    left: rem(60px);
  }

  > :not(:last-child) {
    margin-bottom: $baseGap;
  }

  .notification {
    position: relative;
    transition: 300ms all ease-in-out;
    overflow: hidden;
    padding: 1em;
    background: $alert;
    font-size: rem(12px);
    line-height: rem(16px);
	  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
    width: rem(300px);
    max-width: calc(100vw - 80px);
    transform-origin: left center;
    max-height:9999999px;
    visibility: visible;

    @include breakpoint(large) {
      font-size:rem(16px);
      line-height: rem(22px);
    }

    &,
    a {
      color: $light;
    }

    &.success {
      background: $primary;
      
      &, .close {
        color: #fff;
      }
    }
    &.error {
      background: $alert
    }
    &.success, &.error {
      p {
        margin-bottom: rem(5px);
      }
      p + ul {
        margin-top: rem(5px);
      }
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding-top: 5px;
      padding-right: 8px;
      cursor: pointer;
      color: #fff;
      font-size: 1.2rem;
    }

    &.hide {
      opacity: 0;
      visibility: hidden;
      padding-top: 0;
      padding-bottom: 0;
      margin-left:-100px;
    }
  }
}

html.inProgress {
  pointer-events: none;
  &:before {
    content:"";
    width: 100vw;
    height: 100vh;
    background:rgba(0,0,0,0.5);
    position: fixed;
    top:0;
    left:0;
    z-index:99999998
  }
  &:after {
    content:"";
    position: fixed;
    top: 0;
    height: 0;
    background: url('/images/layout/loading.svg') no-repeat center center;
    height: 100vh;
    width: 100vw;
    z-index:99999999;
    filter:invert(100%);
  }
}

  .naviMain1:last-child{
    padding-bottom: 200px;
  }


@include breakpoint(medium){
  .naviMain1:last-child{
    padding-bottom: 20px;
  }
}


