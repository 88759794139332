@include fontFaceV2(
    $fontName: "icomoon",
    $fileName: "icomoon",
    $types: ("woff2","woff"),
    $style: normal
);

@include fontFaceV2(
    $fontName: "Open Sans", 
    $fileName: "open-sans-v17-latin", 
    $weights: ("300", "400", "600", "800"), 
    $types: all
);