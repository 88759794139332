// Define some variables for collapser usage
$collapser-class: ".collapser";
$collapser-gaps-lr: 1.2rem;
$collapser-gaps-tb: 1rem;
$collapser-color: $secondary; 
$collapser-dark: $dark; 

// Dont Edit Inside the mixin, overwrite styles in @include collapser
@mixin collapser {

    #{$collapser-class} {
        background: $collapser-color;
        padding: $collapser-gaps-tb $collapser-gaps-lr;
        position: relative;
        cursor: pointer;

        &:not(.collapser-initialized) {
            background: none;
            padding:0;

            &:after, &:before {
                display:none;
            }
        }

        &:after, &:before {
            background: $collapser-dark;
            width:25px;
            height: 3px;
            content:"";
            position: absolute;
            right: $collapser-gaps-lr;
            transition: all 300ms;
        }
        &:after {
            top: 50%;
        }
        &:before {
            transform: rotate(90deg);
            top:50%;
        }

        &.active {
            &:before {
                transform: rotate(-135deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }

        & + * {
            overflow:hidden;
            transition: max-height 300ms;

            &.limited {
                overflow-y:scroll;
                margin-bottom: 1rem;
            }
        }

        @content;
    }
}

// Add your custom shine here ;)
@include collapser {
    background: $light;
    padding: rem(10px);
    margin-bottom: rem($baseGap);
    color: $secondary;
    &:after,
    &:before{
        width: rem(15px);
        background: $secondary;
        @include breakpoint(large){
            width: rem(20px);
        }
    }
    h2{
        margin-bottom: 0;
    }
    span{
        display: block;
        line-height: rem(23px);
        padding: 0 rem(18px);
        @include breakpoint(tiny){
            padding: 0;
        }
        @include breakpoint(large){
            line-height: rem($baseLineHeight);
        }
    }
}